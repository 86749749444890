<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>Create A New Form</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="Form Name" label-for="name">
              <b-form-input
                id="name"
                trim
                placeholder="Contact Us Or..."
                v-model="formDetails.name"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" md="6">
          <language-picker @getLanguage="setLanguage"></language-picker>
        </b-col>
        <b-col cols="12" md="6">
          <page-picker @getPage="setPage"></page-picker>
        </b-col>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="Form Description" label-for="description">
              <b-form-textarea
                id="description"
                v-model="formDetails.description"
              ></b-form-textarea>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="success" @click="createNewForm"
          >Create Form</b-button
        >
      </div>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "Create A New Form",
  data() {
    return {
      isLoading: false,
      formDetails: {
        isDeleted: false,
        formId: 0,
        name: null,
        seourl: null,
        seoTitle: null,
        seoDescription: null,
        languageId: null,
        mainId: "",
        createDate: new Date(Date.now()).toISOString(),
        description: null,
        pageId: null,
      },
    };
  },
  methods: {
    setLanguage(language) {
      this.formDetails.languageId = language.languageId;
    },
    setPage(page) {
      this.formDetails.pageId = page.pageId;
    },
    async createNewForm() {
      try {
        this.isLoading = true;
        let _this = this;
        let createNewForm = new CreateNewForm(_this);
        createNewForm.setRequestParamDataObj(_this.formDetails);
        await createNewForm.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Successful",
                icon: "CheckIcon",
                variant: "success",
                text: "Form Created Was Successfully",
              },
            });
            _this.formDetails = {
              isDeleted: false,
              formId: 0,
              name: null,
              seourl: null,
              seoTitle: null,
              seoDescription: null,
              mainId: "",
              createDate: new Date(Date.now()).toISOString(),
              description: null,
            };
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    LanguagePicker,
    PagePicker,
  },
};
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BFormTextarea,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import PagePicker from "../../components/utils/PagePicker.vue";
import { CreateNewForm } from "@/libs/Api/Form";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
</script>
