<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col cols="12">
        <b-form class="w-100" @submit.prevent>
          <b-form-group :label="placeholder" label-for="post-picker">
            <v-select
              @search="handleSearch"
              id="post-picker"
              :filterable="false"
              v-if="pageList"
              v-model="selectedPage"
              :options="pageList"
              label="title"
              placeholder="Choose Page"
            >
              <template #no-options>No page available</template>
            </v-select>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import vSelect from "vue-select";
import { BOverlay, BRow, BCol, BForm, BFormGroup } from "bootstrap-vue";
import { GetAllPages } from "@/libs/Api/Page";
export default {
  emits: ["getPage"],
  async mounted() {
    await this.getAllPages();
    if (this.defaultPageId) {
      let selectedPage = this.pageList.find(
        (item) => item.pageId == this.defaultPageId
      );
      if (selectedPage) {
        this.selectedPage = selectedPage;
      }
    }
  },
  props: {
    defaultPageId: {
      type: [String, Number],
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Choose Your Page",
    },
  },
  watch: {
    selectedPage: {
      handler(val) {
        this.$emit("getPage", val);
      },
    },
  },
  data() {
    return {
      isLoading: false,
      searchCommand: null,
      pageList: null,
      timeout: null,
      selectedPage: null,
    };
  },
  methods: {
    async getAllPages() {
      try {
        this.isLoading = true;
        let qParams = {
          count: 20,
          pageNumber: 1,
        };
        if (this.searchCommand) {
          qParams["searchCommand"] = this.searchCommand;
        }
        let _this = this;
        let getAllPages = new GetAllPages(_this);
        getAllPages.setRequestParam(qParams);
        await getAllPages.fetch((response) => {
          if (response.isSuccess) {
            _this.pageList = response.data.forms;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleSearch(searchValue, loadingFn) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.searchCommand = searchValue;
        loadingFn(true);
        await this.getAllPages();
        loadingFn(false);
      }, 300);
    },
  },
  components: {
    vSelect,
    BOverlay,
    BRow,
    BCol,
    BForm,
    BFormGroup,
  },
};
</script>
